<style scoped>
    .s-root{width:100%; overflow-x: hidden; position: relative;-webkit-backface-visibility: hidden; backface-visibility: hidden; perspective: 1000;}
    .s-body{white-space: nowrap;font-size: 0;display: block;-webkit-backface-visibility: hidden; backface-visibility: hidden;  perspective: 1000;}
    .s-item{display: inline-block; width:100%;}
    .s-indicate{display: flex; align-items: center; position: absolute; right: 20px; bottom: 20px; border-radius: 30px; padding: 5px 10px; background: rgba(0,0,0,0.4); color:#fff;}
    .f14{font-size: 14px;}
    .f12{font-size: 12px;}
</style>
<template>
    <div class="s-root">
        <div class="s-body" @touchstart.stop="touchstart($event)" @touchmove.stop="touchmove($event)" :style="{'-webkit-transform':'translate3d(' + offset +'px,0,0)','-webkit-transition-property' :transition_property,'-webkit-transition-duration':transition_duration}">
            <img :src="el" class="s-item" v-for="(el,index) in list" @load='set_wh($event)'> 
        </div>
        <div class="s-indicate" v-show='has_indicate'>
            <div class='f14'>{{index+1}}</div>
            <div class='f12'>/</div>
            <div class='f12'>{{list.length}}</div>
        </div>
    </div>
</template>
<script>
    export default{
        data(){
            return {
                index: 0
                ,start_x : 0
                ,start_y : 0
                ,init_x : 0
                ,drag : false
                ,distance : window.innerWidth
                ,offset : 0
                ,transition_property:""
                ,transition_duration : "0s"
                ,direction:'left'
                ,body_touch : false
                ,is_first:false
            }
        }
        ,props:{
            list :{
                default: []
            }
            ,has_indicate : true
        }
        ,mounted : async function(){
            document.addEventListener("touchend",this.touchend,{passive:false})
            this.clear_animate();
        }
        ,methods : {
            touchstart(e){
                if(
                    e.touches.length != 1
                ){
                    e.preventDefault();
                    return ;
                }
                this.drag = true;
                this.start_x = e.changedTouches[0].pageX;
                this.start_y = e.changedTouches[0].pageY;
                this.clear_transition();
                this.is_first = true;
            }
            ,set_wh(e){
                var t  =e.target;
                setTimeout(()=>{
                    t.style.width = t.parentNode.clientWidth + 'px';
                    t.style.height = t.parentNode.clientHeight + 'px';
                    t.style['object-fit'] = 'contain';
                },50)
                
            }
            ,touchmove(e){
                if(
                    e.touches.length != 1
                ){
                    e.preventDefault();
                    return;
                }
                var y = e.changedTouches[0].pageY;
                var x = e.changedTouches[0].pageX;
                var offset_x = x- this.start_x;
                var offset_y = y - this.start_y;
                var rs =  +(Math.abs(offset_y / offset_x).toFixed(2)) ;
                if(
                    this.is_first && rs > 0.35
                ){
                    this.drag  = false;
                }else {
                    this.drag = true;
                }
                if(
                    !this.drag
                ){
                    return;
                }else{
                    e.preventDefault();
                }
                this.offset = this.init_x + offset_x;
                this.is_first = false;
            }
            ,touchend(e){
                if(!this.drag)return;
                this.drag = false;
                this.transition_property = "transform";
                this.transition_duration = '0.3s';
                if(
                    this.offset > 0
                ){
                    this.offset = 0;
                }
                if(
                    Math.abs(this.offset) > (this.distance * (this.list.length-1)) 
                ){
                    this.offset = -(this.distance * (this.list.length-1))
                }
                this.set_x(e);
            }
            ,set_x(e){
                var x = e.changedTouches[0].pageX;
                if(
                    x > this.start_x && Math.abs(x-this.start_x) > 50
                ){
                    this.direction = 'right';
                    if(
                        this.index == 0
                    ){
                        this.index = 0 
                    }else{
                        this.index -= 1;
                    }
                } else if(
                    x < this.start_x && Math.abs(x-this.start_x) > 50
                ){
                    this.direction = "left";
                    if(
                        this.index == this.list.length-1
                    ){
                        this.index = this.list.length - 1;
                    }else{
                        this.index += 1;
                    }
                }
                this.offset = -(this.index * this.distance);
                this.init_x = this.offset;
            }
            ,clear_transition(){
                this.transition_property = "none";
                this.transition_duration = '0s';
            }
            ,clear_animate(){
                setTimeout(()=>{
                    this.transition_property = "none";
                    this.transition_duration = '0s';
                })
            }
        }  
    }
</script>