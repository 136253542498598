import x_swiper from "./component/x_swiper.vue";

Vue.component("x_swiper",x_swiper)
// new VConsole();


var tid = null;
var share_url = "https://h5.imacco.cn";
var ax_get = function(url,data){
    return axios({
        url:url
        ,method :'get'
        ,params : data
    })
}
var ax_post = function(url,data,res_type){
    return axios({
        url:url
        ,method :'post'
        ,data:util.stringfy(data)
        ,responseType : res_type || 'json'
    })
}
var info_id = util.parse_query().infoid || "267519"; //30375,30384,25356
var uid = util.parse_query().uid || "66045";
var share_avatar = decodeURIComponent(util.parse_query().avatar) || "";
var share_nickname = decodeURIComponent(util.parse_query().nickname) || "";

var slider = null;								//商品轮播
var slider_pages = null;						//商品轮播分页器
var cur_comment = null;

function format_info_body(str){
    if(str == "") return;
    var rs = str.replace(/[\<\>]/g,"");
    rs = rs.replace(/(&lt;)|(&gt;)|(\u3c)|(\u3e)/g,"");
    return eval("(" + rs + ")");
}

window.get_comment = async function (state){
    if(
        state == 1
    ){
        vm.reco_list = [];
        vm.show_comment = [];
        await vm.get_comment();
    }
}

window.get_remark = function(json){
    var map = JSON.parse(json);
    if(cur_comment == null)return;
    var args = {
        Avatar: vm.user.Avatar
        ,Comment: map.Content || "12345"
        ,CommentLikeCount: 0
        ,CreateTime: util.get_time(Date.now()) || ""
        ,ID: map.CommentID || ""
        ,KeyNO: vm.user.KeyNo
        ,NickName: vm.user.NickName
        ,UID: map.UID || ""
        ,zan: 0
    }
    cur_comment.Back.unshift(args);
    cur_comment.show_remark[0] = args;
    vm.$forceUpdate();
}

var vm = new Vue({
    el:'.wrap'
    ,data:{
        show_ppt: false
        ,show_share : false
        ,key_num:""
        ,shrink_comment : 0
        ,stop_play:false
        ,show_comment:[]
        ,hide_comment:[]
        ,reco_page:1
        ,reco_list:[]
        ,info_id: info_id  //25317,25356
        ,info:{}
        ,info_content:[]
        ,info_tags:[]
        ,video_detail:{}
        ,show_video:false
        ,prod_list:[]
        ,uid: uid 
        ,following:false
        ,zaning:false
        ,saving:false
        ,loading_mask : true
        ,main_show : false
        ,is_loading : true
        ,user:{}
        ,is_wechat : util.is_wechat()
        ,share_avatar : share_avatar
        ,share_nickname : share_nickname
        ,banner_list : []
        ,has_indicate : true
        ,head:{}
        ,extinfo:"{}"
        ,has_mask : true
    }
    , mounted : async function() {
        await this.get_wxconfig()
        this.get_friend();
        await this.get_info();
        await this.get_comment();
        await this.get_user();
        this.xmask_remove();					//移除遮罩回调
        this.stop_scroll_default();				//移除webview默认滚动行为
        $('.root-mask').addClass('none')
        x_msg.remove();
        this.loading_mask = false;
        this.get_head_el()
        this.set_share();
        this.set_extinfo()
        this.has_mask = false
    }
    ,updated:function(){
    }
    
    , methods: {
        xmask_remove : function() {	//遮罩移除回调
            x_mask.removed(() => {
                this.show_ppt = false;
                this.show_share = false;
                $('.ppt-page').addClass('none');
            })
        }
        ,get_head_el(){
            setTimeout(()=>{
                this.head = $('.share-mask')
            })
        }
        ,has_more(){
            var el = document.querySelector('.v-info-body')
            if(
                el
            ){
                var h = el.offsetHeight
                if(
                    h > 200
                ){
                    return true
                }
            }
            return false
        }
        ,do_mask(e) {
            var t = e.currentTarget
            $(t).addClass('none')
            var el = $('.v-info-body')
            el.removeClass('max-h55') 
        }
        ,get_friend: async function(){
            var query = {
                UID : uid
            }
            var res = await axios({
                url : call.Comment_Api_User
                ,method: "get"
                ,params:query
            })
            if(
                !res.data.isSuccess
            ){
                return;
            }
            this.share_nickname = res.data.data[0].NickName || "";
            this.share_avatar = res.data.data[0].Avatar || "";
        }
        ,goto_mdn : function(){
            // util.goto_mdn(info_id,this.info.Type,this.info.CreatorID);
        }
        ,resize_thumb:function(v,wh,num){
            v += '?x-oss-process=image/resize,'+ wh + '_' + num;
            return v ;
        }
        ,get_user : async function(){
            var query = {
                UID: this.uid
            }
            var res = await ax_get(call.Comment_Api_User,query);
            if(res.data.data){
                this.user = res.data.data[0];
            }
        }
        ,video_ended : function(){
            this.stop_play = true;
        }
        ,on_video_play : function(e){
            var t = e.target;
            t.style.background = "url("+ this.video_detail.img.InfoImg.ImageUrl+")" ;
            t.style["background-size"] = "contain";
        }
        ,play_video : function(){							//放video
            var video = document.querySelector('.v-video');
            video.play();
            this.stop_play = false;
            this.is_loading = false;
        }
        ,set_extinfo(){
            this.extinfo = JSON.stringify({
                Info: info_id
                ,infoType: this.info.Type
            })
        }
        ,get_wxconfig : async function(){
            var res = await axios({
                url : call.Comment_Api_WeixinTicket
                ,params : {
                    url : location.href
                }
            })
            var config = res.data.data || {}
            var wx_config = {
                debug: false, // 开启调试模式,调用的所有api的返回值会在客户端alert出来，若要查看传入的参数，可以在pc端打开，参数信息会通过log打出，仅在pc端时才会打印。
                appId: config.appid, // 必填，公众号的唯一标识
                timestamp: config.timestamp, // 必填，生成签名的时间戳
                nonceStr: config.noncestr, // 必填，生成签名的随机串
                signature: config.signature,// 必填，签名
                jsApiList: ["updateAppMessageShareData","updateTimelineShareData"], // 必填，需要使用的JS接口列表
                openTagList: ["wx-open-launch-app"] 
            }
            console.log(wx_config)
            if(
                "wx" in window
            ){
                wx.config(wx_config)
            }
            
        }
        ,set_share: function(){
            var btn = $('.launch-btn');
            btn.on('error', function (e) {
                console.log('fail', e.detail);
                if(
                    util.is_android()
                ){
                    location.href = "https://a.app.qq.com/o/simple.jsp?pkgname=com.imacco.mup004&fromcase=40002"
                }else if(
                    util.is_ios()
                ){
                    location.href = "https://itunes.apple.com/cn/app/meideni/id893109338?mt=8"
                }
            });
            if(
                !("wx" in window)
            ){
                return
            }
            var title = this.info.Title || "";
            var desc = this.info.Description || "";
            if(
                this.video_detail.img 
                && this.video_detail.img.newShareimg
            ){
                var share_img = this.video_detail.img.newShareimg
            }else{
                var share_img = ""
            }
            wx.ready(function () {   //需在用户可能点击分享按钮前就先调用
                wx.updateAppMessageShareData({ 
                    title: title , // 分享标题
                    desc: desc, // 分享描述
                    link: location.href, // 分享链接，该链接域名或路径必须与当前页面对应的公众号JS安全域名一致
                    imgUrl: share_img, // 分享图标
                    success: function () {
                    }
                })
                wx.updateTimelineShareData({ 
                    title: title, // 分享标题
                    link: location.href, // 分享链接，该链接域名或路径必须与当前页面对应的公众号JS安全域名一致
                    imgUrl: share_img, // 分享图标
                    success: function () {
                    }
                })
            });
        }
        ,remark_comment : function(e,item){
            cur_comment = item;
            var args = {
                InfoKeyNO: this.info.KeyNo
                ,ParentID : item.ID
            };
            util.inject('replayComment',args);
        }
        ,reply: function(){
            var args = {
                InfoKeyNO :this.info.KeyNo
            };
            util.inject('reviewArticle',args);
        }
        ,share_activity : function(){
            var title = this.info.Title || "";
            // var desc = this.info.Description || "";
            var desc = document.querySelector('.v-info-body').textContent.slice(0,30) + '...';
            var query = location.search;
            if(
                this.video_detail.img 
                && this.video_detail.img.newShareimg
            ){
                var cover = this.video_detail.img.newShareimg;
            }else {
                var cover = "";
            }
            var args = {
                title :title
                ,desc : desc
                ,url : share_url + '/article_share.html' + query
                ,cover: cover
            };
            util.inject('share_activity',args);
        }
        ,goto_usercenter: function(e,uid){
            var args = {
                UID : "" + uid 
            };
            util.inject('goto_userCenter',args);
            
        }
        ,goto_tag:function(e,item){
            var tag_id = item.ID;
            var args = {
                id : tag_id
            };
            util.inject('goto_tag',args);
        }
        ,goto_prod: function(e,item,type){
            var prod_no = item.ProductNO;
            if(
                type=="content"
            ){
                var prod_id = item.ID;
            }else{
                var prod_id = item.ProductID;
            }
            var uid = this.uid;
            var url = "product.html?uid=" + uid + "&product_no=" + prod_no;
            var args = {
                uid	:this.uid
                ,product_id : prod_id
                ,product_no : item.ProductNO
                ,try_makeup :item.isrelatedstyle ? 1 : 0
            };
            util.inject('goto_prod',args);
        }
        ,goto_article(e,item){
            var args = {
                uid	: this.uid
                ,infoid	: item.ID
            };
            util.inject('goto_info',args);
        }
        
        ,change_save: async function(e,state,item){
            if(
                this.saving
            ){
                return;
            }
            this.saving = true;
            var t = e.target;
            this.info.save = state;
            if(
                state != 1
            ){
                this.info.appCollectCount -= 1;
            }else{
                this.info.appCollectCount += 1;
            }
            var info = {
                TypeID : 6
                ,UID: uid
                ,OperateVal:state    //1 执行 。0 取消
                ,KeyNO:item.KeyNo 
            }
            var res = await ax_post(call.Comment_Api_Operation,info);
            this.saving = false;
            if(
                !res.data
            ){
                x_msg.msg('网络错误',2000);
            }
        }
        ,change_follow : async function(e,state){
            if(
                this.following
            ){
                return;
            }
            this.following = true;
            var t = e.target;
            this.info.isFollow = state;
            if(
                state == true
            ){
                var v = 1;
            }else{
                var v = 0;
            }
            var info = {
                TypeID : 7
                ,UID: uid
                ,OperateVal:v    //1 执行 。0 取消
                ,KeyNO:this.info.CreatorID   //被关注用户的UID
            }
            var res = await ax_post(call.Comment_Api_Follow,info);
            this.following = false;
            if(
                !res.data
            ){
                x_msg.msg('网络错误',2000);
            }
        }
        ,change_zan: async function(e,state,item){
            if(
                this.zaning
            ){
                return;
            }
            this.zaning = true;
            var t = e.target;
            this.info.zan = state;
            if(
                state != 1
            ){
                this.info.appLikeCount -= 1;
            }else{
                this.info.appLikeCount += 1;
            }
            var info = {
                TypeID : 4
                ,UID: uid
                ,OperateVal:state    //1 执行 。0 取消
                ,KeyNO:item.KeyNo
            }
            var res = await ax_post(call.Comment_Api_Operation,info);
            this.zaning = false;
            if(
                !res.data
            ){
                x_msg.msg('网络错误',2000);
            }
        }
        ,change_reco_zan: async function(e,item,state){
            var info = {
                TypeID : 4
                ,UID: uid
                ,OperateVal:state    //1 执行 。0 取消
                ,KeyNO:item.KeyNo
            }
            var res = await ax_post(call.Comment_Api_Operation,info);
            if(
                state == 1
            ){
                item.LikeCount = (+item.LikeCount)+1;
                item.zan = 1;
            }else{
                item.LikeCount = (+item.LikeCount)-1;
                item.zan = 0;
            }
        }
        ,change_comment_zan: async function(e,state,item){
            var t = e.target;
            var info = {
                TypeID : 3
                ,UID: uid
                ,OperateVal:state    //1 执行 。0 取消
                ,KeyNO: "Comment" + item.ID   //被关注用户的UID
            }
            var res = await ax_post(call.Comment_Api_Operation,info);
            item.zan = state;
            if(
                state == 1
            ){
                item.CommentLikeCount += 1;
            }else{
                item.CommentLikeCount -= 1;
            }
        }

        ,get_info: async function(){
            var query = {
                InfoID:this.info_id  
                ,uid:uid
            }
            var res = await ax_get(call.Info_Api_Info,query);
            res = res.data;
            if(!res.data)return;
            this.info = res.data.infodata;
            if(
                this.info.Content != null
                && this.info.Content
            ){
                this.info_content = format_info_body(this.info.Content);
            }else{
                this.info_content = "";
            }
            this.info_tags = this.info.TagList;
            this.prod_list = this.info.ProductJson || [];
            this.key_num = this.info.KeyNo;
            this.video_detail = res.data.static || {};
            this.set_component();
            this.set_prod_slider();
        }
        ,set_prod_slider : function(){
            var me = this;
            setTimeout(function(){
                me.init_prod_slider();				    //初始化商品轮播
                me.init_prod_pages();					//初始化商品轮播分页器
                me.prod_slider_changed();				//商品轮播切换回调
            })
        }
        ,set_component: function(){
            if (this.info.ImageUrls == null || !this.info.ImageUrls) return
            this.banner_list = this.info.ImageUrls.split(",")
            this.banner_list.forEach(function(el, index, list) {
                list[index] = el + "?x-oss-process=image/resize,w_800"
            });
        }
        ,get_reco: async function(){
            var query = {
                CurrentPage: this.reco_page
                // ,IsExp : 1
                ,Order : "CreateTime"
                ,PageSize :4
                ,IsPublish:1 
                ,LoginUID : uid || -1
                ,Tag:(this.info_tags.map(function(item){return item.ID})).join(",")
                ,InfoID:info_id
            }
            var res = await ax_get(call.Info_Api_Infos,query);
            res = res.data.data;
            this.reco_record_count = res.TotalNum;
            x_msg.remove();
            this.loading_mask = false;
            var list = res.Data;
            if(list.length == 0)return;
            this.reco_list = this.reco_list.concat(list);
            
        }

        ,ppt_page_remove : function(e){     //移除ppt页码
            var t = e.currentTarget;
            $(t).addClass('none');
            x_mask.remove();
        }

        ,all_remark  : function(e,map){
            map.shrink_remark = -1;
            // map.show_remark = JSON.parse(JSON.stringify(map.Back)); 
            map.show_remark = map.Back; 
            this.$forceUpdate();
        }
        ,part_remark  : function(e,map){
            map.shrink_remark = 1;
            map.show_remark = [];
            if(
                map.Back.length > 0
            ){
                map.show_remark.push(map.Back[0]);
            }
            
        }
        ,all_comment : function(){
            this.shrink_comment = -1;
            // this.show_comment = JSON.parse(JSON.stringify(this.hide_comment));
            this.show_comment = this.hide_comment;
            this.$forceUpdate();
        }
        ,part_comment : function(){
            this.shrink_comment = 1;
            this.show_comment = [];
            this.show_comment.push(this.hide_comment[0]);
        }
        ,get_comment :async function(){
            var query = {
                KeyNO : this.key_num
                ,UID : this.uid
            }
            var res = await ax_get(call.Comment_api_CommentsNew,query);
            res = res.data.data;
            if( !res)return;
            this.info.comment_record_count = res.TotalNum;
            var list = res.Data;
            if(list.length == 0)return;
            this.wash_comment(list); //洗
            
        }
        ,wash_comment  : function(list){
            list.forEach((el,i,list)=>{
                el.remark_show = -1;	//显示全部remark
                el.shrink_content = 1;	//折叠评论内容
                el.shrink_remark = 1 	//折叠remark
                el.show_remark = [];
                el.Back = el.Back || [];
                var list = el.Back;
                if(
                    list.length > 0
                ){
                    el.show_remark.push(list[0]);
                }
            })
            // this.hide_comment = JSON.parse(JSON.stringify(list));
            this.hide_comment = list;
            this.shrink_comment = 1;
            this.show_comment.push(list[0]);
            this.$forceUpdate();
        }
        ,stop_scroll_default : function(){					//移除webview默认滚动行为
            new Stop_scroll_default({
                el:'.v-view'
            });
        }
        ,stop_touchmove : function(e){
            e.preventDefault();
        }
        ,change_share : function(e,is_show){	//显示隐藏分享
            if(is_show){
                x_mask.add(0.4);
                this.show_share = true;
            }else{
                x_mask.remove();
                this.show_share = false;
            }
            
        }
        , play_ppt : function(e,selector) {			//播放评论中的幻灯片
            var t = e.target;
            var index = t.getAttribute('data-index');
            var dad = t.closest(selector);
            var imgs = [].slice.call(dad.querySelectorAll('img'));
            var list = [];
            imgs.forEach((el) => {
                list.push(el.getAttribute('src'));
            });
            x_mask.add();
            this.show_ppt = true;
            clearTimeout(tid);
            tid = setTimeout(() => {
                var ppt = new X_swiper({
                    el: '.v-ppt'
                    , imgs: list
                    ,has_indicate:-1
                })
                ppt.to(index);
                $('.ppt-page').removeClass('none');
                $('.ppt-page-index').text(ppt.index +1);
                $('.ppt-page-count').text(ppt.count);
                ppt.changed(function(){
                    $('.ppt-page-index').text(ppt.index +1);
                    $('.ppt-page-count').text(ppt.count);
                });
            }, 150)

        }
        , init_prod_slider : function() {		//初始化商品轮播
            slider = new X_slider({
                el: '.v-prod-wrap'
                , view_h: "80px"
            });
        }

        , init_prod_pages : function() {	//初始化商品轮播分页器
            slider_pages = new X_pages({
                el: '.v-prod-pages'
                , count: slider.count
            })
        }
        , prod_slider_changed : function() {	//商品轮播切换回调
            slider.changed(() => {
                slider_pages.to(slider.index, slider.direct);
            })
        }

    }

})


