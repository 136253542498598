var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "s-root" }, [
    _c(
      "div",
      {
        staticClass: "s-body",
        style: {
          "-webkit-transform": "translate3d(" + _vm.offset + "px,0,0)",
          "-webkit-transition-property": _vm.transition_property,
          "-webkit-transition-duration": _vm.transition_duration,
        },
        on: {
          touchstart: function ($event) {
            $event.stopPropagation()
            return _vm.touchstart($event)
          },
          touchmove: function ($event) {
            $event.stopPropagation()
            return _vm.touchmove($event)
          },
        },
      },
      _vm._l(_vm.list, function (el, index) {
        return _c("img", {
          staticClass: "s-item",
          attrs: { src: el },
          on: {
            load: function ($event) {
              return _vm.set_wh($event)
            },
          },
        })
      }),
      0
    ),
    _vm._v(" "),
    _c(
      "div",
      {
        directives: [
          {
            name: "show",
            rawName: "v-show",
            value: _vm.has_indicate,
            expression: "has_indicate",
          },
        ],
        staticClass: "s-indicate",
      },
      [
        _c("div", { staticClass: "f14" }, [_vm._v(_vm._s(_vm.index + 1))]),
        _vm._v(" "),
        _c("div", { staticClass: "f12" }, [_vm._v("/")]),
        _vm._v(" "),
        _c("div", { staticClass: "f12" }, [_vm._v(_vm._s(_vm.list.length))]),
      ]
    ),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }