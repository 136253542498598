var api = require("!../../../node_modules/_style-loader@1.3.0@style-loader/dist/runtime/injectStylesIntoStyleTag.js");
            var content = require("!!../../../node_modules/_css-loader@3.6.0@css-loader/dist/cjs.js??ref--6-1!../../../node_modules/_vue-loader@15.9.8@vue-loader/lib/loaders/stylePostLoader.js!../../../node_modules/_sass-loader@8.0.2@sass-loader/dist/cjs.js??ref--6-2!../../../node_modules/_resolve-url-loader@3.1.4@resolve-url-loader/index.js!../../../node_modules/_vue-loader@15.9.8@vue-loader/lib/index.js??vue-loader-options!./x_swiper.vue?vue&type=style&index=0&id=179a1fb0&scoped=true&lang=css&");

            content = content.__esModule ? content.default : content;

            if (typeof content === 'string') {
              content = [[module.id, content, '']];
            }

var options = {};

options.insert = "head";
options.singleton = false;

var update = api(content, options);



module.exports = content.locals || {};